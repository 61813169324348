import React, { useState } from "react";
import {
  HeaderContainer,
  Logo,
  HamburgerMenuContainer,
  MobileMenu,
  DesktopNavContainer,
  DesktopNav,
  SocialsDiv,
} from "./styles/header";
import useWindowSize from "../../hooks/useWindowSize";
import { HashLink } from "react-router-hash-link";

export default function Header() {
  const [clicked, setClicked] = useState(false);

  function handleClick() {
    setClicked((prevClicked) => !prevClicked);
  }

  // console.log(clicked);
  const size = useWindowSize();
  // console.log(size.width);

  return (
    <HeaderContainer click={clicked}>
      <Logo src="./images/logo.png" />
      {size.width < 480 && (
        <HamburgerMenuContainer click={clicked} onClick={handleClick}>
          <div className="bar1"></div>
          <div className="bar2"></div>
          <div className="bar3"></div>
        </HamburgerMenuContainer>
      )}
      {clicked && (
        <MobileMenu>
          <div>
            <HashLink smooth to="/#featured" onClick={handleClick}>
              Artwork
            </HashLink>
            <HashLink smooth to="/#about" onClick={handleClick}>
              About
            </HashLink>
            <HashLink smooth to="/#join" onClick={handleClick}>
              Mint
            </HashLink>
            <HashLink smooth to="/#roadmap" onClick={handleClick}>
              Roadmap
            </HashLink>
            <HashLink smooth to="/#team" onClick={handleClick}>
              Team
            </HashLink>
          </div>
        </MobileMenu>
      )}
      {size.width > 480 && (
        <DesktopNavContainer>
          <DesktopNav>
            <div>
              <HashLink smooth to="/#featured">
                Artwork
              </HashLink>
              <HashLink smooth to="/#about">
                About
              </HashLink>
              <HashLink smooth to="/#join">
                Mint
              </HashLink>
              <HashLink smooth to="/#roadmap">
                Roadmap
              </HashLink>
              <HashLink smooth to="/#team">
                Team
              </HashLink>
            </div>
          </DesktopNav>
          <SocialsDiv>
            <a href="https://twitter.com/EiffelForYouNFT">
              <img src={"./images/twitter-small.png"} alt="Twitter" />
            </a>
            <a href="#0">
              <img src={"./images/discord-small.png"} alt="Discord" />
            </a>
            <a href="https://www.instagram.com/eiffelforyounft/">
              <img
                src={"./images/instagram-logo.png"}
                style={{
                  width: "44px",
                  height: "44px",
                }}
                alt="Instagram"
              />
            </a>
            <a href="#0">
              <img src={"./images/telegram-small.png"} alt="Open Sea" />
            </a>
          </SocialsDiv>
        </DesktopNavContainer>
      )}
    </HeaderContainer>
  );
}
