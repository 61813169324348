import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { GlobalStyles } from "./global-styles";
import { Fonts } from "./fonts/fonts";
import App from "./App";

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Fonts />
      <GlobalStyles />
      <App />
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);
