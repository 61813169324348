import React from "react";
import {
  Container,
  FollowContainer,
  SectionTitle,
  SocialsDiv,
  Logo,
  Line,
  FooterNavDiv,
  CopyrightPara,
  FooterNav,
  FollowContentContainer,
} from "./styles/footer";
import useWindowSize from "../../hooks/useWindowSize";
import { Layout } from "../index";
import { HashLink } from "react-router-hash-link";

export default function Footer() {
  const year = new Date().getFullYear();
  const { width } = useWindowSize();
  // console.log(width);

  return (
    <Container>
      {/* <Layout> */}
      <FollowContainer>
        <Layout>
          <FollowContentContainer>
            <SectionTitle>Follow Us</SectionTitle>
            <SocialsDiv>
              <a href="https://twitter.com/EiffelForYouNFT">
                <img src={"./images/twitter-small.png"} alt="Twitter" />
              </a>
              <a href="#0">
                <img src={"./images/discord-small.png"} alt="Discord" />
              </a>
              <a href="https://www.instagram.com/eiffelforyounft/">
                <img
                  src={"./images/instagram-logo.png"}
                  style={{
                    width: width < 480 ? "35px" : "44px",
                    height: width < 480 ? "35px" : "44px",
                  }}
                  alt="Instagram"
                />
              </a>
              <a href="#0">
                <img src={"./images/telegram-small.png"} alt="Open Sea" />
              </a>
            </SocialsDiv>
          </FollowContentContainer>
        </Layout>
      </FollowContainer>
      {/* <Line />
      <Logo src="./images/logo.png" /> */}
      <Line>
        <HashLink smooth to="/#top">
          <Logo src="./images/logo.png" />
        </HashLink>
      </Line>
      <Layout>
        <FooterNavDiv>
          <CopyrightPara>
            Copyright © {year} Eiffel For You. All rights reserved.
          </CopyrightPara>
          <FooterNav>
            <div>
              <HashLink smooth to="/#featured">
                Artwork
              </HashLink>
              <HashLink smooth to="/#about">
                About
              </HashLink>
              <HashLink smooth to="/#join">
                Mint
              </HashLink>
              <HashLink smooth to="/#roadmap">
                Roadmap
              </HashLink>
              <HashLink smooth to="/#team">
                Team
              </HashLink>
            </div>
          </FooterNav>
        </FooterNavDiv>
      </Layout>
      {/* </Layout> */}
    </Container>
  );
}
