import React from "react";
import {
  CardContainer,
  DateTitle,
  CardText,
  Img,
  Heart,
} from "./styles/roadmapCard";

export default function RoadmapCard({
  width,
  setTrainPosition,
  position,
  date,
  text,
}) {
  //   const [hover, setHover] = useState(false);

  // console.log(hover);

  return (
    <CardContainer
      //   onMouseOver={() => setHover(true)}
      //   onMouseOut={() => setHover(false)}
      onMouseOver={() => setTrainPosition(position)}
    >
      <DateTitle>{date}</DateTitle>
      <CardText>{text}</CardText>
      {/* {hover && <Heart />} */}
      {width > 1100 && <Img src="./images/rail-short.png" />}
      <Heart />
    </CardContainer>
  );
}
