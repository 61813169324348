import React, { useState, useEffect } from "react";
import { Container } from "./styles/backToTop";
import { HashLink } from "react-router-hash-link";
import { IoIosArrowDropup } from "react-icons/io";

export default function BackToTop() {
  //   const [scrolling, setScrolling] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);

  // console.log(scrollTop);

  useEffect(() => {
    function onScroll() {
      let currentPosition = window.pageYOffset; // or use document.documentElement.scrollTop;
      //   if (currentPosition > scrollTop) {
      //     // downscroll code
      //     setScrolling(false);
      //   } else {
      //     // upscroll code
      //     setScrolling(true);
      //   }
      setScrollTop(currentPosition <= 0 ? 0 : currentPosition);
    }

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollTop]);

  return (
    <Container scrollTop={scrollTop}>
      <HashLink smooth to="#top">
        <IoIosArrowDropup />
      </HashLink>
    </Container>
  );
}
