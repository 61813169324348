import { createGlobalStyle } from "styled-components";
import TheSecretRegular from "./TheSecretRegular.woff";

export const Fonts = createGlobalStyle`

    @font-face {
        font-family: 'Secret';
        src: local('Secret'), local('Secret'),
        url(${TheSecretRegular}) format('woff');
    }
`;
