import styled from "styled-components/macro";

export const Container = styled.div`
  background: rgba(0, 0, 0, 0.6);
  padding: 45px 38px;
  border-radius: 10px;

  @media only screen and (min-width: 480px) {
    padding: 45px 30px;
  }
`;

export const Image = styled.img`
  height: 130px;
  width: 130px;
`;

export const Name = styled.h3`
  margin: 21px auto 14px auto;
  font-weight: 600;
  font-size: 20px;
  line-height: 15px;
  color: #ffffff;
`;

export const Role = styled.h4`
  margin: 0;
  font-weight: 400;
  font-size: 18px;
  line-height: 15px;
  color: #676767;
`;

export const HorizontalRule = styled.hr`
  height: 4px;
  width: 81px;
  background: #a4e8ff;
  border-radius: 20px;
  margin: 25px auto 13px auto;
`;

export const Description = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  text-align: center;
  color: #676767;
  margin: 0;
`;
