import React from "react";
import { Container, SectionTitle, Paragraph } from "./styles/featured";
import { Layout } from "../index";
import useWindowSize from "../../hooks/useWindowSize";

import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import "swiper/swiper.scss"; // core Swiper
import "swiper/modules/navigation/navigation.scss"; // Navigation module
import "swiper/modules/pagination/pagination.scss"; // Pagination module

export default function Featured() {
  const size = useWindowSize();

  return (
    <Container id="featured">
      <Layout>
        <SectionTitle>Featured Artwork</SectionTitle>
        <Paragraph>
          Take a look in the gallery below to see samples of the artwork in our
          glorious collection. In the Eiffel Tower&#8217;s dressing room you can
          find 15 trait groups and over 144 individual traits. That&#8217;s a
          lot of variety! The iconic French landmark is travelling around the
          world and sporting a lot of different fashion looks.
        </Paragraph>
        <Swiper
          modules={[Navigation, Pagination, Scrollbar, A11y]}
          spaceBetween={size.width > 480 ? 15 : 0}
          slidesPerView="auto"
          navigation
          // pagination={{ clickable: true }}
          scrollbar={{ draggable: true }}
          loop={true}
          // loopFillGroupWithBlank={true}
          // onSwiper={(swiper) => console.log(swiper)}
          // onSlideChange={() => console.log("slide change")}
        >
          <SwiperSlide>
            <img src="./images/featured/featured-1.png" alt="Featured" />
          </SwiperSlide>
          <SwiperSlide>
            <img src="./images/featured/featured-2.png" alt="Featured" />
          </SwiperSlide>
          <SwiperSlide>
            <img src="./images/featured/featured-3.png" alt="Featured" />
          </SwiperSlide>
          <SwiperSlide>
            <img src="./images/featured/featured-4.png" alt="Featured" />
          </SwiperSlide>
          <SwiperSlide>
            <img src="./images/featured/featured-5.png" alt="Featured" />
          </SwiperSlide>
          <SwiperSlide>
            <img src="./images/featured/featured-6.png" alt="Featured" />
          </SwiperSlide>
          <SwiperSlide>
            <img src="./images/featured/featured-7.png" alt="Featured" />
          </SwiperSlide>
          <SwiperSlide>
            <img src="./images/featured/featured-8.png" alt="Featured" />
          </SwiperSlide>
        </Swiper>
      </Layout>
    </Container>
  );
}
