import React from "react";
import {
  Banner,
  Featured,
  About,
  Join,
  Roadmap,
  Team,
  Footer,
  BackToTop,
} from "./components";
import { Switch, Route } from "react-router-dom";

function App() {
  return (
    <div style={{ position: "relative" }}>
      {/* <h1>
        <span style={{ color: "#A4E8FF" }}>Eiffel</span>{" "}
        <span style={{ color: "#FFFFFF" }}>For</span>{" "}
        <span style={{ color: "#f90690" }}>You</span>
      </h1>
      <a href="https://www.youtube.com/watch?v=dQw4w9WgXcQ" target="_blank">
        Coming soon...
      </a> */}
      <Switch>
        <Route exact path="/">
          <Banner />
          <Featured />
          <About />
          <Join />
          <Roadmap />
          <Team />
          <Footer />
          <BackToTop />
        </Route>
      </Switch>
    </div>
  );
}

export default App;
