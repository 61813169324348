import React from "react";
import {
  Container,
  Title,
  ButtonsContainer,
  Button,
  Paragraph,
} from "./styles/banner";
// import { BsArrowDown } from "react-icons/bs";
import { Layout, Header } from "../index";
import { HashLink } from "react-router-hash-link";
import { ReactComponent as ArrowDown } from "../../arrow-down.svg";

export default function Banner() {
  return (
    <Container>
      <Layout>
        <Header />
        <Title>
          <span style={{ color: "#A4E8FF" }}>Eiffel</span>{" "}
          <span style={{ color: "#FFFFFF" }}>For</span>{" "}
          <span style={{ color: "#f90690" }}>You</span>
        </Title>
        <ButtonsContainer>
          <Button background="#F90690" border="none">
            Gallery
          </Button>
          <Button background="none" border="1px solid #F90690">
            Mint
          </Button>
        </ButtonsContainer>
        <Paragraph>
          The Eiffel For You collection consists of 2,500 adorable Eiffel Tower
          depictions, each having their own individual traits. Each artwork is
          completely unique and hand-drawn by artist portraying several items
          that are distinctive to the French lifestyle.
        </Paragraph>
        <HashLink smooth to="/#featured">
          {/* <img src="/images/arrow-down.png" alt="arrow down" /> */}
          <ArrowDown />
        </HashLink>
      </Layout>
    </Container>
  );
}
