import styled from "styled-components/macro";

export const Container = styled.section`
  padding: 40px 15px;

  @media only screen and (min-width: 480px) {
    /* padding: 100px 200px 190px 200px; */
    position: relative;
    /* Animation add */
    padding: 100px 0px 100px 0px;
  }
`;

export const SectionTitle = styled.h2`
  margin: 0px;
  font-weight: 700;
  font-size: 25px;
  line-height: 35px;
  color: var(--textWhite);

  @media only screen and (min-width: 480px) {
    text-align: center;
    font-size: 35px;
    /* Animation add */
    margin: 0px 100px;
  }

  @media only screen and (min-width: 900px) {
    text-align: left;
    margin: 0px 200px;
  }
`;

export const Paragraph = styled.p`
  line-height: 28px;
  text-align: center;
  font-size: 14px;

  @media only screen and (min-width: 480px) {
    text-align: left;
    font-size: 16px;
    margin: 20px 0;
    max-width: 850px;
    /* Animation add */
    margin: 20px 100px;
  }

  @media only screen and (min-width: 900px) {
    margin: 20px 200px;
  }
`;

export const CardsGrid = styled.div`
  margin-top: 40px;
  display: grid;
  grid-template: auto / auto;
  grid-gap: 40px;

  @media only screen and (min-width: 1024px) {
    margin: 40px 20px 0px 20px;
    grid-template: 1fr / repeat(4, 1fr);
    grid-gap: 20px;
    justify-content: center;
    margin-top: 70px;
    // overflow-x: clip;
  }

  @media only screen and (min-width: 1440px) {
    grid-template: 1fr / repeat(4, 300px);
    grid-gap: 0px;
    justify-content: center;
    margin-top: 70px;
  }
`;

export const TrainDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-top: 40px;
  gap: 8px;
  // width: 98vw;
  // position: absolute;
  // left: 0px;
  /* Added for animation */
  position: relative;
  width: 100%;
  height: 60px;

  .rails {
    filter: invert(14%) sepia(92%) saturate(7406%) hue-rotate(318deg)
      brightness(99%) contrast(101%);
    width: 100%;
    height: 4px;
    /* Added */
    position: absolute;
    top: 100%;
  }

  .train {
    height: auto;
    width: 700px;
    /* Added for animation testing purposes */
    position: absolute;
    top: 0%;
    transition: 0.5s;
    left: ${(props) => props.trainPosition};
  }
`;
