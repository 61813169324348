import styled from "styled-components/macro";

export const Container = styled.section`
  padding: 40px 15px;
`;

export const Inner = styled.div`
  display: grid;
  grid-template: auto / auto;
  grid-gap: 15px;

  @media only screen and (min-width: 900px) {
    padding: 80px 150px;
    grid-template: 1fr / 1fr 60%;
    grid-gap: 80px;
  }
`;

export const Acronym = styled.h2`
  margin: 0;
  font-family: "Secret", sans-serif;
  font-weight: 400;
  color: #f90690;
  font-size: 120px;

  @media only screen and (min-width: 480px) {
    font-size: 220px;
    justify-self: center;
    align-self: center;
    margin-top: 40px;
  }
`;

export const TextContainer = styled.div`
  @media only screen and (min-width: 480px) {
    text-align: left;
  }
`;

export const SectionTitle = styled.h2`
  margin: 0px;
  font-weight: 700;
  font-size: 25px;
  line-height: 35px;
  color: var(--textWhite);

  @media only screen and (min-width: 480px) {
    font-size: 35px;
  }
`;

export const Paragraph = styled.p`
  font-size: 14px;
  line-height: 28px;

  @media only screen and (min-width: 480px) {
    font-size: 16px;
    margin-top: 20px;
    line-height: 36px;
  }
`;

export const SocialsDiv = styled.div`
  //background: url("../images/twitter-small.png");
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;

  @media only screen and (min-width: 480px) {
    justify-content: flex-start;
    margin-top: 30px;
  }

  img {
    margin-right: 15px;
  }

  img:hover {
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    filter: invert(100%);
  }
`;
