import styled from "styled-components/macro";

export const Container = styled.section`
  padding: 40px 15px;
  /* background: url("../images/eiffel-silhouette.png") bottom,
    url("../images/join-background.png"); */
  /* background: url("../images/silhouette.png") bottom,
    url("../images/join-background.png"); */
  background: url("../images/silhouette.png"),
    url("../images/join-background.png");
  background-position: bottom -2px center, center center;
  background-repeat: no-repeat;
  background-size: contain, cover;
  height: 300px;
  border: 1px solid transparent;

  @media only screen and (min-width: 480px) {
    height: auto;
    padding: 100px 0px 150px 0px;
    background-size: 700px, cover;
  }
`;

export const SectionTitle = styled.h2`
  margin: 0;
  font-weight: 700;
  line-height: 50px;
  font-size: 25px;
  color: #000;

  @media only screen and (min-width: 480px) {
    font-size: 40px;
    margin-bottom: 20px;
  }
`;

export const Button = styled.button`
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  // font-size: 18px; Desktop
  line-height: 15px;
  color: #000;
  border-radius: 5px;
  border: none;
  background: #fff;
  // padding: 30px 80px; Desktop
  padding: 15px 40px;
  width: 200px;
  box-shadow: 0px 40px 40px rgba(54, 41, 71, 0.68);
  margin-top: 15px;
  transition: all 0.3s;

  @media only screen and (min-width: 480px) {
    padding: 18px 40px;
  }

  &:hover {
    cursor: pointer;
    background: #f90690;
    color: #fff;
  }
`;
