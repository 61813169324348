import styled from "styled-components/macro";

export const Img = styled.img`
  position: absolute;
  top: 86%;
  width: 4px;
  height: 74px;
  transition: 0.1s;
`;

// const Wrapper = styled.div`
//   &:hover ${Button} {
//     display: none;
//   }
// `

export const CardContainer = styled.div`
  background: #212121;
  border-radius: 10px;
  padding: 40px 10px 60px 10px;
  width: 80%;
  margin: 0 auto;
  position: relative;
  // z-index: -5;
  // opacity: 0.6;

  @media only screen and (min-width: 700px) {
    width: 50%;
  }

  @media only screen and (min-width: 1024px) {
    width: 100%;
  }

  @media only screen and (min-width: 1100px) {
    width: 80%;
    padding: 40px 20px 40px 20px;

    /* &:hover {
      transition: 0.5s;
      scale: 1.1;
    } */

    &:hover ${Img} {
      filter: invert(14%) sepia(92%) saturate(7406%) hue-rotate(318deg)
        brightness(99%) contrast(101%);
    }
  }
`;

export const DateTitle = styled.h3`
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  color: #fff;
`;

export const CardText = styled.p`
  line-height: 25px;
  text-align: center;
  font-size: 14px;
`;

export const Heart = styled.div`
  background-image: url("../images/heart-image.png");
  background-size: 100% 100%;
  display: inline-block;
  height: 35px;
  width: 40px;
  position: absolute;
  top: -7%;
  left: 45%;

  @media only screen and (min-width: 480px) {
    left: 42%;
  }
`;
