import styled from "styled-components/macro";

export const Container = styled.section`
  background: url("../images/banner.png");
  // background-position: center;
  background-position: 51% 45px;
  background-repeat: no-repeat;
  background-size: cover;
  // Change if necessary later...
  // height: 100%;
  // position: absolute;
  padding: 0px 0px 20px 0px;

  @media only screen and (min-width: 480px) {
    padding: 0px;
    background-position: 52% -180px;
    background-size: auto;
    // background-attachment: fixed;
    padding-bottom: 80px;
  }

  @media only screen and (min-width: 2600px) {
    background-size: cover;
  }

  svg {
    fill: #a4e8ff;
  }
`;

export const Title = styled.h1`
  font-family: "Secret", sans-serif;
  font-size: 85px;
  font-weight: 400;
  margin: 0;
  padding-top: 80px;

  @media only screen and (min-width: 480px) {
    font-size: 160px;
    padding-top: 160px;
  }
`;

export const ButtonsContainer = styled.div`
  display: grid;
  grid-template: auto / auto;
  grid-gap: 20px;
  justify-content: center;
  padding-top: 40px;

  @media only screen and (min-width: 480px) {
    padding-top: 0px;
    margin-top: -20px;
    grid-template: 1fr / 1fr 1fr;
    width: 420px;
    margin: 0 auto;
    grid-gap: 30px;
  }
`;

export const Button = styled.button`
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  // font-size: 18px; Desktop
  line-height: 15px;
  color: var(--textWhite);
  border: ${(props) => props.border};
  border-radius: 5px;
  background: ${(props) => props.background};
  // padding: 30px 80px; Desktop
  padding: 15px 40px;
  width: 200px;
  transition: 0.5s;

  @media only screen and (min-width: 480px) {
    padding: 23px 0px;

    &:hover {
      cursor: pointer;
      transform: scale(1.1);
    }
  }
`;

export const Paragraph = styled.p`
  line-height: 28px;
  text-align: center;
  font-size: 14px;
  padding: 20px 15px 0px 15px;

  @media only screen and (min-width: 480px) {
    width: 650px;
    margin: 0 auto;
    padding: 30px 0px;
    font-size: 16px;
  }

  @media only screen and (min-width: 1000px) {
    width: 800px;
  }
`;

// export const Arrow = styled.div`
//   position: relative;
//   width: 32px;
//   margin: 50px auto;
//   height: 0;
//   border-bottom: 3px solid #a4e8ff;
//   transform: rotate(90deg);

//   &::after {
//     content: "";
//     width: 0;
//     height: 0;
//     border-top: 10px solid transparent;
//     border-bottom: 10px solid transparent;
//     border-left: 15px solid #a4e8ff;
//     position: absolute;
//     right: -10px;
//     top: -8px;
//   }
// `;
