import React, { useState } from "react";
import {
  Container,
  SectionTitle,
  Paragraph,
  CardsGrid,
  TrainDiv,
} from "./styles/roadmap";
import { Layout, RoadmapCard } from "../index";
import useWindowSize from "../../hooks/useWindowSize";

export default function Roadmap() {
  const [trainPosition, setTrainPosition] = useState("0%");

  const size = useWindowSize();

  return (
    <Container id="roadmap">
      <Layout>
        <SectionTitle>Roadmap</SectionTitle>
        <Paragraph>
          The Eiffel For You collection is not just about art, but also about
          expanding the NFT utility. We’ll be introducing many new
          functionalities that will make it one-of-a-kind, just like the real
          Eiffel became a symbol in the real world, even though it was just made
          as an exhibition originally.
        </Paragraph>
        <CardsGrid>
          <RoadmapCard
            width={size.width}
            setTrainPosition={setTrainPosition}
            position={size.width > 2000 ? "10%" : "2%"}
            date="Phase 1"
            text="Genesis Mint of the collection and launch on an NFT marketplace."
          />
          <RoadmapCard
            width={size.width}
            setTrainPosition={setTrainPosition}
            position={size.width > 2000 ? "30%" : "17.5%"}
            date="Phase 2"
            text="Launch of the NFT staking pool that will reward users with passive income in stablecoins."
          />
          <RoadmapCard
            width={size.width}
            setTrainPosition={setTrainPosition}
            position={size.width > 2000 ? "42%" : "37.5%"}
            date="Phase 3"
            text="Launch of the EFY token that will be used as the centrepiece of the Eiffel For You ecosystem."
          />
          <RoadmapCard
            width={size.width}
            setTrainPosition={setTrainPosition}
            position={size.width > 2000 ? "63%" : "51%"}
            date="Phase 4"
            text="Launch of our own merchandise and creation of a new sister collection with a new look!"
          />
        </CardsGrid>
      </Layout>
      {size.width > 1100 && (
        <TrainDiv trainPosition={trainPosition}>
          <img className="train" src="./images/train.png" alt="train" />
          <img className="rails" src="./images/rails.png" alt="rails" />
        </TrainDiv>
      )}
    </Container>
  );
}
