import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
  :root {
      --background: #000000;
      --text: #959595;
      --textWhite: #ffffff;
      --buttonBackground: #F90690;
      --buttonText: #FFFFFF;
  }

  *, 
  *:before, 
  *:after {
    box-sizing: border-box;
  }

  // Remove and rather use layout component?
  /* html {
    max-width: 1920px;
    margin: 0 auto;
  } */

  // html,
  body {
    // height: 100%;
    margin: 0;
    padding: 0;
    // font-family: 'Secret', sans-serif;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--background);
    color: var(--text);
    font-size: 16px;
    text-align: center;
    // Prevent train animation to spread outside bounds on smaller screens
    overflow-x: hidden;
  }

  // Remove this later...
  /* div {
    text-align: center;
  } */
`;
