import styled from "styled-components/macro";

export const Container = styled.section`
  background: #212121;
  padding: 40px 15px;

  @media only screen and (min-width: 480px) {
    padding: 100px 200px 150px 200px;
  }
`;

export const SectionTitle = styled.h2`
  margin: 0px;
  font-weight: 700;
  font-size: 25px;
  line-height: 35px;
  color: var(--textWhite);

  @media only screen and (min-width: 480px) {
    text-align: left;
    font-size: 35px;
    margin-bottom: 60px;
  }
`;

export const CardsContainer = styled.div`
  display: grid;
  grid-template: auto / auto;
  grid-gap: 15px;
  margin-top: 25px;

  @media only screen and (min-width: 480px) {
    // grid-template: 1fr / repeat(4, 300px);
    justify-content: center;
    // repeat(auto-fit, minmax(200px, 1fr))
    grid-template: 1fr / repeat(auto-fit, minmax(200px, 1fr));
  }
`;
