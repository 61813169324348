import React from "react";
import { Container, SectionTitle, Button } from "./styles/join";
import { Layout } from "../index";

export default function Join() {
  return (
    <Container id="join">
      <Layout>
        <SectionTitle>Join Les Parisiens</SectionTitle>
        <Button>Mint</Button>
      </Layout>
    </Container>
  );
}
