import styled from "styled-components/macro";

export const Container = styled.div`
  display: none;
  position: fixed;
  /* top: 78%;
  left: 86%; */
  // max-width: 1920px;
  // height: 30px;
  z-index: 100;
  top: 88%;
  left: 95%;

  @media only screen and (min-width: 1024px) {
    /* height: 75px;
    margin: 0; */
    display: block;
    top: 88%;
    left: 93.5%;
  }

  @media only screen and (min-width: 1440px) {
    /* height: 75px;
    margin: 0; */
    display: block;
    top: 91%;
    left: 94.5%;
  }

  @media only screen and (min-width: 1500px) {
    /* height: 75px;
    margin: 0; */
    display: block;
    top: 88%;
    left: 94.5%;
  }

  @media only screen and (min-width: 1920px) {
    top: 91%;
    left: 95.5%;
    display: block;
  }

  @media only screen and (min-width: 2560px) {
    top: 95%;
    display: block;
  }

  svg {
    height: ${(props) => (props.scrollTop > 500 ? "50px" : "0px")};
    width: 50px;
    transition: 0.5s;

    /* @media only screen and (min-width: 480px) {
      height: ${(props) => (props.scrollTop > 800 ? "40px" : "0px")};
      width: 60px;
    } */

    @media only screen and (min-width: 1100px) {
      height: ${(props) => (props.scrollTop > 800 ? "60px" : "0px")};
      width: 60px;
    }
  }

  svg:hover {
    scale: 1.1;
    color: #a2e8fe;
  }

  a:visited,
  a:link,
  a:active,
  a:focus {
    color: #ffffff;
  }
`;
