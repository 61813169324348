import React from "react";
import {
  Container,
  Image,
  Name,
  Role,
  HorizontalRule,
  Description,
} from "./styles/teamCard";

export default function TeamCard({ name, role, description, src }) {
  return (
    <Container>
      <Image src={`./images/team/${src}.png`} />
      <Name>{name}</Name>
      <Role>{role}</Role>
      <HorizontalRule />
      <Description>{description}</Description>
    </Container>
  );
}
