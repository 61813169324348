import styled from "styled-components/macro";

export const Container = styled.footer`
  background: var(--background);
  // position: relative;
  // z-index: -5;

  @media only screen and (min-width: 480px) {
    // z-index: 0;
  }
`;

export const FollowContainer = styled.div`
  background: linear-gradient(270deg, #000000 21.31%, rgba(0, 0, 0, 0) 100%),
    url("./images/footer-background.png");
  background-repeat: no-repeat;
  background-size: cover;
  // height: 200px;
  padding: 40px 0px 60px 0px;

  @media only screen and (min-width: 480px) {
    /* margin-left: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    padding: 40px 300px 60px 0px; */
    background: linear-gradient(270deg, #000000 35.31%, rgba(0, 0, 0, 0) 100%),
      url("./images/footer-background.png");
    background-size: cover;
    background-repeat: no-repeat;
    padding: 0px;
  }
`;

export const FollowContentContainer = styled.div`
  @media only screen and (min-width: 480px) {
    margin-left: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 0px;
  }

  @media only screen and (min-width: 1100px) {
    margin-left: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    padding: 40px 300px 60px 0px;
    /* background: linear-gradient(270deg, #000000 35.31%, rgba(0, 0, 0, 0) 100%),
      url("./images/footer-background.png");
    background-size: cover;
    background-repeat: no-repeat; */
  }
`;

export const SectionTitle = styled.h2`
  font-family: "Secret", sans-serif;
  font-weight: 400;
  color: #ffffff;
  font-size: 70px;
  margin: 0;
  //padding: 30px 0px 0px 0px;

  @media only screen and (min-width: 480px) {
    margin-top: 150px;
    font-size: 100px;
  }
`;

export const SocialsDiv = styled.div`
  //background: url("../images/twitter-small.png");
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
  //padding-bottom: 20px;
  padding-left: 15px;

  @media only screen and (min-width: 480px) {
    margin-bottom: 200px;
    padding-left: 0px;
    margin-right: 10px;
  }

  img {
    margin-right: 15px;
    height: 35px;

    @media only screen and (min-width: 480px) {
      height: 44px;
    }
  }

  img:hover {
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    filter: invert(100%);
  }
`;

export const Line = styled.div`
  background: linear-gradient(
    to right,
    #a4e8ff 33.33%,
    #ffffff 33.33% 66.66%,
    #f90690 66.66% 100%
  );
  height: 10px;
  position: relative;
`;

// Move Logo to Line div and position it from there?
export const Logo = styled.img`
  position: absolute;
  height: 70px;
  top: -320%;
  left: 41.5%;

  @media only screen and (min-width: 480px) {
    height: 100px;
    /* top: 79%;
    left: 47%; */
    top: -500%;
    left: calc(50% - 50px);
  }
`;

export const FooterNavDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 10px 20px 10px;

  @media only screen and (min-width: 480px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    //padding: 40px 200px 20px 100px;
    // gap: 0px 70px;
    padding: 25px 20px;
  }
`;

export const CopyrightPara = styled.p`
  font-size: 13px;
  line-height: 20px;
  color: #959595;
  // margin-bottom: 15px;
  margin-top: 0px;

  @media only screen and (min-width: 480px) {
    width: 100%;
    padding-left: 5px;
    font-size: 14px;
    text-align: left;
    margin-top: 16px;
  }

  @media only screen and (min-width: 900px) {
    padding-left: 20px;
    font-size: 16px;
  }

  @media only screen and (min-width: 1100px) {
    padding-left: 50px;
  }
`;

export const FooterNav = styled.div`
  font-size: 16px;
  line-height: 20px;
  color: #959595;
  width: 100%;

  @media only screen and (min-width: 480px) {
    //margin-left: 70px;
  }

  div {
    // list-style-type: none;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 0px;
    // width: 100%;
    margin-bottom: 0px;

    @media only screen and (min-width: 480px) {
      margin-top: 0px;
      // justify-content: center;
    }
  }

  a {
    transition: all 0.3s;
    color: var(--text);
    text-decoration: none;
  }

  a:visited,
  a:link,
  a:active,
  a:focus {
    color: var(--text);
  }

  a:hover {
    color: white;
    cursor: pointer;
  }
`;
