import styled from "styled-components/macro";

export const HeaderContainer = styled.header`
  // background: transparent;
  // background: #65c7e5;
  // padding: 15px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${(props) => props.click && "black"};
  position: ${(props) => props.click && "fixed"};
  width: 100%;
  z-index: 9999;

  @media only screen and (min-width: 900px) {
    padding: 10px 50px;
  }

  @media only screen and (min-width: 1400x) {
    padding: 10px 200px;
  }
`;

export const Logo = styled.img`
  height: 50px;
  margin: 17px 20px;

  @media only screen and (min-width: 900px) {
    height: 75px;
    margin: 0;
  }
`;

export const HamburgerMenuContainer = styled.div`
  display: inline-block;
  cursor: pointer;
  margin: 17px 20px;

  .bar1,
  .bar2,
  .bar3 {
    width: 35px;
    height: 4px;
    background-color: #ffffff;
    margin: 7px 0;
    transition: 0.4s;
  }

  .bar1 {
    -webkit-transform: ${(props) =>
      props.click && "rotate(-45deg) translate(-9px, 6px)"};
    transform: ${(props) =>
      props.click && "rotate(-45deg) translate(-9px, 6px)"};
  }

  .bar2 {
    opacity: ${(props) => props.click && "0"};
  }

  .bar3 {
    //-webkit-transform: rotate(45deg) translate(-8px, -8px);
    //transform: rotate(45deg) translate(-8px, -8px);
    -webkit-transform: ${(props) =>
      props.click && "rotate(45deg) translate(-8px, -8px)"};
    transform: ${(props) =>
      props.click && "rotate(45deg) translate(-8px, -8px)"};
  }
`;

export const MobileMenu = styled.nav`
  position: fixed;
  background: #65c7e5;
  top: 84px;
  width: 100%;
  height: 100%;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;

  div {
    // list-style-type: none;
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding-left: 0px;
    margin-top: -60px;
  }

  a {
    font-size: 22px;
    transition: 0.5s;
    font-weight: 500;
    letter-spacing: 1px;
    color: #ffffff;
    text-decoration: none;
  }

  a:visited,
  a:link,
  a:active,
  a:focus {
    color: #ffffff;
  }

  a:hover {
    cursor: pointer;
    color: black;
  }
`;

export const DesktopNavContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const DesktopNav = styled.nav`
  width: 450px;

  @media only screen and (min-width: 900px) {
    width: 550px;
  }

  div {
    // list-style-type: none;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: 16px 0px;
    padding-left: 40px;
  }

  a {
    // width: 100%;
    // margin-right: 40px;
    font-weight: 400;
    font-size: 18px;
    line-height: 15px;
    // color: #959595;
    transition: color 0.3s;

    /* Underline code */
    /* position: relative;
    display: inline-block;
    padding-bottom: 10px;
    margin-top: 10px; */
    margin-top: 25px;
    padding-bottom: 25px;
    width: 100%;
    // overflow: visible;
    // transition: 0.5;
    position: relative;
    color: var(--text);
    text-decoration: none;
  }

  a:visited,
  a:link,
  a:active,
  a:focus {
    color: var(--text);
  }

  /* Underline image in pseudo element */
  a::before {
    content: "";
    background: no-repeat bottom url(images/nav-underline.png);
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    opacity: 0;
  }

  a:hover {
    font-weight: 600;
    color: #ffffff;
    cursor: pointer;
    transition: 0.3s;
  }

  /* Appear the underline image on hover */
  a:hover::before {
    opacity: 1;
    transition: 0.5s;
  }

  /* Underline code */
  // Second option for underline on nav link hover
  // Similar to the one implemented for GoldMine Token
  /* li:after {
    content: "";
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #a4e8ff;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }

  li:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
  } */

  // Replaced by putting the image into the ::before element
  // To delete or ignore
  // https://developer.mozilla.org/en-US/docs/Web/CSS/background
  // background: no-repeat center/80% url("../img/image.png"); !!!
  // li:hover {
  /* background: url(images/nav-underline.png) bottom repeat-x; */
  /* background: no-repeat bottom center/80% url(images/nav-underline.png); */
  // overflow: visible;
  // padding-bottom: 15px;
  // }
`;

export const SocialsDiv = styled.div`
  //background: url("../images/twitter-small.png");
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
  margin-left: 5px;

  @media only screen and (min-width: 900px) {
    margin-left: 50px;
  }

  img {
    height: 40px;
    margin-right: 15px;
  }

  img:hover {
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    filter: invert(100%);
  }
`;
