import React from "react";
import { Layout, TeamCard } from "../index";
import { Container, SectionTitle, CardsContainer } from "./styles/team";

export default function Team() {
  return (
    <Container id="team">
      <Layout>
        <SectionTitle>Team</SectionTitle>
        <CardsContainer>
          <TeamCard
            name="Luka"
            role="Blockchain Developer"
            description="Experienced blockchain developer that helps to develop the backbone of the project."
            src="luca"
          />
          <TeamCard
            name="Marjolaine"
            role="The Artist"
            description="Our gifted French artist that created this amazing hand-made collection in front of you."
            src="maxine"
          />
          <TeamCard
            name="Maxine"
            role="Product Manager"
            description="French in-house marketing specialist and product manager that shapes the project itself."
            src="marsolo"
          />
          <TeamCard
            name="Klemen"
            role="Full-Stack Developer"
            description="Our highly-skilled full-stack developer that makes sure all content looks crisp on your screens."
            // description="Our full-stack developer that makes sure all content looks crisp on your screens."
            src="klemen"
          />
        </CardsContainer>
      </Layout>
    </Container>
  );
}
