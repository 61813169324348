import styled from "styled-components/macro";

export const Container = styled.section`
  background: #212121;
  /* padding: 40px 15px 80px 15px; */
  padding: 40px 15px 40px 15px;
  // Remove after adding carousel
  height: auto;

  @media only screen and (min-width: 480px) {
    /* padding: 100px 20px; */
    padding: 50px 20px 35px 20px;
    height: auto;
  }

  @media only screen and (min-width: 1100px) {
    padding: 100px 20px 35px 20px;
  }

  // Swiper styling
  .swiper:hover {
    cursor: pointer;
  }

  .swiper-slide.swiper-slide-active {
    width: 100% !important;
    height: 100% !important;
  }

  .swiper-slide {
    width: 100% !important;
    height: 100% !important;
  }

  .swiper-slide img {
    width: 100%;
    border-radius: 10px;
  }

  // #a11062
  /* .swiper-button-prev,
  .swiper-button-next {
    color: #a11062;
  } */

  // Change navigation arrows position and appearance
  .swiper {
    /* overflow-x: clip;
    overflow-y: visible; */
    height: 390px;
    /* width: 100%; */

    @media only screen and (min-width: 380px) {
      height: 410px;
    }

    @media only screen and (min-width: 400px) {
      height: 440px;
    }

    @media only screen and (min-width: 420px) {
      height: 450px;
    }

    @media only screen and (min-width: 480px) {
      height: 515px;
    }
  }

  .swiper-button-next,
  .swiper-button-prev {
    /* top: 111%; */
    top: 97%;

    &:focus {
      color: #f90690;
      -webkit-border: 0px solid #f90690;
      border: 0px solid #f90690;
      outline: none !important;
    }
  }

  .swiper-button-prev,
  .swiper-rtl .swiper-button-next {
    left: 15%;
    right: auto;
    outline: none !important;
  }

  .swiper-button-next,
  .swiper-rtl .swiper-button-prev {
    right: 15%;
    left: auto;
    outline: none !important;
  }

  .swiper-button-next {
    background-image: url(/images/carousel-right.svg);
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
    width: 92px;
  }

  .swiper-button-next::after {
    display: none;
  }

  .swiper-button-prev {
    background-image: url(/images/carousel-left.svg);
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
    width: 92px;
  }

  .swiper-button-prev::after {
    display: none;
  }

  .swiper-button-next:hover,
  .swiper-button-prev:hover {
    filter: invert(26%) sepia(91%) saturate(6421%) hue-rotate(314deg)
      brightness(94%) contrast(108%);
  }

  @media only screen and (min-width: 480px) {
    .swiper-slide.swiper-slide-active {
      width: auto !important;
      height: 100% !important;
    }

    .swiper-slide {
      width: auto !important;
      height: 100% !important;
    }
  }

  @media only screen and (min-width: 480px) {
    .swiper-button-prev,
    .swiper-rtl .swiper-button-next {
      left: 35%;
    }

    .swiper-button-next,
    .swiper-rtl .swiper-button-prev {
      right: 32%;
    }
  }

  @media only screen and (min-width: 900px) {
    .swiper-button-prev,
    .swiper-rtl .swiper-button-next {
      left: 15%;
    }

    .swiper-button-next,
    .swiper-rtl .swiper-button-prev {
      right: 65%;
    }
  }
`;

export const SectionTitle = styled.h2`
  margin: 0px;
  font-weight: 700;
  font-size: 25px;
  line-height: 35px;
  color: var(--textWhite);

  @media only screen and (min-width: 480px) {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 35px;
    margin: 0px 0px 0px 0px;
  }

  @media only screen and (min-width: 900px) {
    margin: 0px 0px 0px 50px;
    justify-content: flex-start;
  }

  @media only screen and (min-width: 1400px) {
    margin: 0px 0px 0px 200px;
  }

  &::before {
    content: "";
    background-image: url("../images/heart-image.png");
    background-size: 100% 100%;
    display: inline-block;
    height: 25px;
    width: 25px;
    margin-right: 10px;
    //margin-top: 5px;
    position: relative;
    top: 3px;
    // transform: scale(0.5);
    // z-index: -5;

    @media only screen and (min-width: 480px) {
      // z-index: 0;
      width: 50px;
      height: 40px;
      margin-right: 25px;
    }
  }
`;

export const Paragraph = styled.p`
  line-height: 28px;
  text-align: center;
  font-size: 14px;

  @media only screen and (min-width: 500px) {
    padding: 10px 0px 10px 0px;
  }

  @media only screen and (min-width: 900px) {
    text-align: left;
    font-size: 16px;
    width: 800px;
    padding: 20px 0px 50px 0px;
    margin: 0px 0px 0px 50px;
  }

  @media only screen and (min-width: 1400px) {
    margin: 0px 0px 0px 200px;
  }
`;
