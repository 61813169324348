import React from "react";
import {
  Container,
  Inner,
  Acronym,
  TextContainer,
  SectionTitle,
  Paragraph,
  SocialsDiv,
} from "./styles/about";
import { Layout } from "../index";
// import TwitterSmall from "./images/twitter-small.png";
// import Test from "images/twitter-small.png";

export default function About() {
  return (
    <Container id="about">
      <Layout>
        <Inner>
          <Acronym>EFY</Acronym>
          <TextContainer>
            <SectionTitle>About Us</SectionTitle>
            <Paragraph>
              The Eiffel For You collection consists of 2,500 adorable Eiffel
              Tower depictions, each having their own individual traits. Each
              artwork is completely unique and hand-drawn by a gifted artist
              portraying several items that are unique to the French lifestyle.
              Come take a stroll at the picturesque esplanade of the digital
              Seine river and enjoy the breath-taking sight of the most famous
              landmark in Paris and the entire world! C'est la vie!
            </Paragraph>
            <Paragraph>
              All Eiffel For You holders gain access to the exclusive Club Les
              Parisiens, where all the chic high-society bourgeoise can meet to
              discuss and share ideas in private. 2,500 Eiffel Towers have now
              been released into the glamorous crypto-sphere, with
              yield-farming, additional companion collections and much more
              coming soon.
            </Paragraph>
            <SocialsDiv>
              <a href="https://twitter.com/EiffelForYouNFT">
                <img src={"./images/twitter-small.png"} alt="Twitter" />
              </a>
              <a href="#0">
                <img src={"./images/discord-small.png"} alt="Discord" />
              </a>
              <a href="https://www.instagram.com/eiffelforyounft/">
                <img
                  src={"./images/instagram-logo.png"}
                  style={{
                    width: "44px",
                    height: "44px",
                  }}
                  alt="Instagram"
                />
              </a>
              <a href="#0">
                <img src={"./images/telegram-small.png"} alt="Open Sea" />
              </a>
            </SocialsDiv>
          </TextContainer>
        </Inner>
      </Layout>
    </Container>
  );
}
